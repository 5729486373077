import { useEffect, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import VehicleCard from '../../components/vehicle_card';
import VehicleDetail from '../../components/vehicle_detail';
import Dropdown from '../../components/dropdown';
import ProgressStepper from '../../components/progress_stepper';
import Button from '../../components/button';
import EmptyState from '../../components/empty_state';

import useVehicle from '../../hooks/use_vehicle';
import useVehiclePricing from '../../hooks/use_vehicle_pricing';
import useOnboardingFormState from '../../hooks/use_onboarding_form_state';

import DynamicLoader from './dynamic_loader';
import OnboardingIdentityData from './step_identity_data';
import OnboardingWorkData from './step_work_data';
import OnboardingDriverData from './step_driver_data';
import ValidationResult from './step_validation_result';
import Prepayment from './step_prepayment';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import MissingKeys from '../../assets/images/missing-keys.jpg';

const renderVehicleEmptyState = () => {
    return (
        <>
            <br />
            <br />
            <br />
            <EmptyState
                title="¡No encontramos el auto!"
                subtitle={<span>
                    Si crees que se trata de un error <Button variant="link" href={'https://wa.me/56954565621?text=Hola%21+Tengo+un+problema+solicitando+un+connect+car'} className="inline">avísanos</Button>
                </span>}
                src={MissingKeys}
            >
                <Button variant="secondary" href="https://www.connectcar.cl" className="mt-5">
                    Volver a connect car
                </Button>
            </EmptyState>
        </>
    );
};

const INITIAL_FORM_STATE = {
    identity: {},
    driver: {},
    work: {},
};

const PROGESS_STEPS = [
    'Personal',
    'Laboral',
    'Conductor',
];

const ONBOARDING_STEPS = {
    IDENTITY: 0,
    WORK: 1,
    DRIVER: 2,
    NEEDS_MANUAL_VALIDATION: 'NMV',
    CAN_CONTINUE_FLOW: 'CCF',
    INVALID_DELAY_REJECTION: 'IDR',
    NEEDS_PREPAYMENT_TO_CONTINUE: 'NPC',
    PREPAYMENT: 4,
};

const INITIAL_STEP = ONBOARDING_STEPS.IDENTITY;

const OnboardingPage = (props) => {
    const { vehicleId, periodsAmount, periodType, annualMileage, mileageUnitMeasure } = props;

    const [currentUserId, setCurrentUserId] = useState(undefined);
    const [currentSubscriptionId, setCurrentSubscriptionId] = useState(undefined);
    const [prevalidationResult, setPrevalidationResult] = useState(undefined);

    const [currentStep, setCurrentStep] = useState(INITIAL_STEP);
    const [formState, setFormState] = useOnboardingFormState(INITIAL_FORM_STATE);

    useEffect(() => {
        if ([1, 2, 3].includes(currentStep)) return;

        localStorage.removeItem('formState');
    }, [currentStep]);

    const handleStepFormOnChange = (stepData) => {
        const newFormState = {
            ...formState,
            [currentStep]: stepData,
        };

        setFormState(newFormState);
        localStorage.setItem('formState', JSON.stringify(newFormState));
    };

    const [vehicle] = useVehicle(vehicleId);
    const [vehiclePricing, setVehiclePricing] = useVehiclePricing(vehicleId, periodType, periodsAmount, annualMileage, mileageUnitMeasure);

    const handleNextStep = (payload) => {
        let nextStep;

        switch (currentStep) {
        case ONBOARDING_STEPS.IDENTITY:
            setCurrentUserId(payload.userId);
            setCurrentSubscriptionId(payload.subscriptionId);

            nextStep = ONBOARDING_STEPS.WORK;
            break;

        case ONBOARDING_STEPS.WORK:
            nextStep = ONBOARDING_STEPS.DRIVER;
            break;

        case ONBOARDING_STEPS.DRIVER:
            setPrevalidationResult(payload);
            nextStep = payload.status;
            break;

        case ONBOARDING_STEPS.NEEDS_PREPAYMENT_TO_CONTINUE:
            nextStep = ONBOARDING_STEPS.PREPAYMENT;
            break;

        case ONBOARDING_STEPS.PREPAYMENT: {
            const { status } = payload;

            if (status === ONBOARDING_STEPS.CAN_CONTINUE_FLOW) {
                const { prepaidAmount, periodPrice } = payload;

                setVehiclePricing({
                    ...vehiclePricing,
                    periodPrice,
                    prepaidAmount,
                });
            }

            nextStep = status;
            break;
        }
        default:
            return;
        }

        setCurrentStep(nextStep);
    };

    const invalidQuery = _.isNull(vehicleId) || _.isNull(periodType) || _.isNull(periodsAmount);
    const invalidEntities = _.isNull(vehicle) || _.isNull(vehicle) || _.isNull(vehiclePricing);

    if (invalidQuery || invalidEntities) {
        return renderVehicleEmptyState();
    }

    if (_.isUndefined(vehicle) || _.isUndefined(vehiclePricing)) {
        return (
            <DynamicLoader icon={MainIcon} fullscreen />
        );
    }

    const renderTitle = () => {
        switch (currentStep) {
        case ONBOARDING_STEPS.PREPAYMENT:
            return (
                <Row className="my-3">
                    <Col>
                        <Title textAlign="center" size="md" variant="secondaryNewTheme">
                            Prepago
                        </Title>
                    </Col>
                </Row>
            );

        default:
            return (
                <Row className="my-3">
                    <Col>
                        <Title textAlign="center" size="md" variant="primary" >
                            Solicitud automóvil
                        </Title>
                    </Col>
                </Row>
            );
        }
    };

    const renderVehicleCard = () => {
        if (currentStep === ONBOARDING_STEPS.PREPAYMENT) return null;

        return (
            <Row>
                <Col>
                    <VehicleCard vehicle={vehicle} />
                </Col>
            </Row>
        );
    };

    const renderVehicleDetail = () => {
        if (currentStep === ONBOARDING_STEPS.PREPAYMENT) return null;

        return (
            <Row className="mb-5">
                <Col>
                    <Dropdown title={<b>Detalle solicitud</b>}>
                        <VehicleDetail vehicle={vehicle} vehiclePricing={vehiclePricing} />
                    </Dropdown>
                </Col>
            </Row>
        );
    };

    const renderProgressStepper = () => {
        if (currentStep === ONBOARDING_STEPS.NEEDS_MANUAL_VALIDATION) return null;
        if (currentStep === ONBOARDING_STEPS.CAN_CONTINUE_FLOW) return null;
        if (currentStep === ONBOARDING_STEPS.INVALID_DELAY_REJECTION) return null;
        if (currentStep === ONBOARDING_STEPS.NEEDS_PREPAYMENT_TO_CONTINUE) return null;
        if (currentStep === ONBOARDING_STEPS.PREPAYMENT) return null;

        return (

            <Row className="mb-4">
                <Col>
                    <ProgressStepper steps={PROGESS_STEPS} currentStep={currentStep} />
                </Col>
            </Row>
        );
    };

    const renderOnboardingStep = () => {
        switch (currentStep) {
        case ONBOARDING_STEPS.IDENTITY:
            return (
                <Row>
                    <Col>
                        <OnboardingIdentityData
                            vehicle={vehicle}
                            vehiclePricing={vehiclePricing}
                            formState={formState[currentStep]}
                            onFormChange={handleStepFormOnChange}
                            onNextStep={handleNextStep}
                        />
                    </Col>
                </Row>
            );
        case ONBOARDING_STEPS.WORK:
            return (
                <Row>
                    <Col>
                        <OnboardingWorkData
                            userId={currentUserId}
                            formState={formState[currentStep]}
                            onFormChange={handleStepFormOnChange}
                            onNextStep={handleNextStep}
                        />
                    </Col>
                </Row>
            );
        case ONBOARDING_STEPS.DRIVER:
            return (
                <Row>
                    <Col>
                        <OnboardingDriverData
                            userId={currentUserId}
                            subscriptionId={currentSubscriptionId}
                            formState={formState[currentStep]}
                            onFormChange={handleStepFormOnChange}
                            onNextStep={handleNextStep}
                        />
                    </Col>
                </Row>
            );
        case ONBOARDING_STEPS.CAN_CONTINUE_FLOW:
        case ONBOARDING_STEPS.INVALID_DELAY_REJECTION:
        case ONBOARDING_STEPS.NEEDS_MANUAL_VALIDATION:
        case ONBOARDING_STEPS.NEEDS_PREPAYMENT_TO_CONTINUE:
            return (
                <Row>
                    <Col>
                        <ValidationResult
                            userId={currentUserId}
                            subscriptionId={currentSubscriptionId}
                            currentStep={currentStep}
                            onNextStep={handleNextStep}
                        />
                    </Col>
                </Row>
            );
        case ONBOARDING_STEPS.PREPAYMENT:
            return (
                <Row>
                    <Col>
                        <Prepayment
                            userId={currentUserId}
                            subscriptionId={currentSubscriptionId}
                            prevalidationResult={prevalidationResult}
                            vehicle={vehicle}
                            vehiclePricing={vehiclePricing}
                            onNextStep={handleNextStep}
                        />
                    </Col>
                </Row>
            );
        }
    };

    const renderBackButton = () => {
        if (currentStep === ONBOARDING_STEPS.NEEDS_MANUAL_VALIDATION) return null;
        if (currentStep === ONBOARDING_STEPS.CAN_CONTINUE_FLOW) return null;
        if (currentStep === ONBOARDING_STEPS.INVALID_DELAY_REJECTION) return null;
        if (currentStep === ONBOARDING_STEPS.NEEDS_PREPAYMENT_TO_CONTINUE) return null;
        if (currentStep === ONBOARDING_STEPS.PREPAYMENT) return null;

        return (
            <Row className="mt-2 mb-4">
                <Col>
                    <Button variant="link" block onClick={() => window.close() }>
                        Volver a la ficha
                    </Button>
                </Col>
            </Row>
        );
    };

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    { renderTitle() }
                    { renderVehicleCard() }
                    { renderVehicleDetail() }
                    { renderProgressStepper() }
                    { renderOnboardingStep() }
                    { renderBackButton() }
                </Col>
            </Row>
        </Container>
    );
};

OnboardingPage.propTypes = {
    vehicleId: PropTypes.string.isRequired,
    periodsAmount: PropTypes.number.isRequired,
    periodType: PropTypes.string.isRequired,
    annualMileage: PropTypes.number.isRequired,
    mileageUnitMeasure: PropTypes.oneOf(['km', 'mi']),
};

export default OnboardingPage;
