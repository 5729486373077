export default (props) => (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M12 25.8H10.8C10.4686 25.8 10.2 26.0687 10.2 26.4C10.2 26.7314 10.4686 27 10.8 27H12C12.3313 27 12.6 26.7314 12.6 26.4C12.6 26.0687 12.3313 25.8 12 25.8Z" fill="#131353" />
        <path d="M28.8 25.8H14.4C14.0687 25.8 13.8 26.0687 13.8 26.4C13.8 26.7314 14.0687 27 14.4 27H28.8C29.1314 27 29.4 26.7314 29.4 26.4C29.4 26.0687 29.1314 25.8 28.8 25.8Z" fill="#0095E8" />
        <path d="M12 22.2H10.8C10.4686 22.2 10.2 22.4686 10.2 22.8C10.2 23.1313 10.4686 23.4 10.8 23.4H12C12.3313 23.4 12.6 23.1313 12.6 22.8C12.6 22.4686 12.3313 22.2 12 22.2Z" fill="#131353" />
        <path d="M28.8 22.2H14.4C14.0687 22.2 13.8 22.4686 13.8 22.8C13.8 23.1313 14.0687 23.4 14.4 23.4H28.8C29.1314 23.4 29.4 23.1313 29.4 22.8C29.4 22.4686 29.1314 22.2 28.8 22.2Z" fill="#0095E8" />
        <path d="M12 18.6H10.8C10.4686 18.6 10.2 18.8686 10.2 19.2C10.2 19.5313 10.4686 19.8 10.8 19.8H12C12.3313 19.8 12.6 19.5313 12.6 19.2C12.6 18.8686 12.3313 18.6 12 18.6Z" fill="#131353" />
        <path d="M28.8 18.6H14.4C14.0687 18.6 13.8 18.8686 13.8 19.2C13.8 19.5313 14.0687 19.8 14.4 19.8H28.8C29.1314 19.8 29.4 19.5313 29.4 19.2C29.4 18.8686 29.1314 18.6 28.8 18.6Z" fill="#0095E8" />
        <path d="M12 15H10.8C10.4686 15 10.2 15.2686 10.2 15.6C10.2 15.9313 10.4686 16.2 10.8 16.2H12C12.3313 16.2 12.6 15.9313 12.6 15.6C12.6 15.2686 12.3313 15 12 15Z" fill="#131353" />
        <path d="M28.8 15H14.4C14.0687 15 13.8 15.2686 13.8 15.6C13.8 15.9313 14.0687 16.2 14.4 16.2H28.8C29.1314 16.2 29.4 15.9313 29.4 15.6C29.4 15.2686 29.1314 15 28.8 15Z" fill="#0095E8" />
        <path d="M12 11.4H10.8C10.4686 11.4 10.2 11.6687 10.2 12C10.2 12.3314 10.4686 12.6 10.8 12.6H12C12.3313 12.6 12.6 12.3314 12.6 12C12.6 11.6687 12.3313 11.4 12 11.4Z" fill="#131353" />
        <path d="M28.8 11.4H14.4C14.0687 11.4 13.8 11.6687 13.8 12C13.8 12.3314 14.0687 12.6 14.4 12.6H28.8C29.1314 12.6 29.4 12.3314 29.4 12C29.4 11.6687 29.1314 11.4 28.8 11.4Z" fill="#0095E8" />
        <path d="M32.9922 8.9622C32.9892 8.90963 32.9789 8.85773 32.9616 8.808C32.955 8.7888 32.9502 8.7702 32.9418 8.7516C32.9126 8.68635 32.872 8.6268 32.8218 8.5758L24.4218 0.1758C24.3708 0.125625 24.3112 0.084975 24.246 0.0558C24.2274 0.0474 24.2088 0.0426 24.1902 0.036C24.14 0.01875 24.0877 0.00825 24.0348 0.0048C24.0246 0.0066 24.0132 0 24 0H7.2C6.86865 0 6.6 0.26865 6.6 0.6V2.4H3.6C3.26865 2.4 3 2.66865 3 3V35.4C3 35.7313 3.26865 36 3.6 36H28.8C29.1313 36 29.4 35.7313 29.4 35.4V32.4H32.4C32.7313 32.4 33 32.1314 33 31.8V9C33 8.9868 32.9934 8.9754 32.9922 8.9622ZM24.6 2.0484L30.9516 8.4H24.6V2.0484ZM28.2 34.8H4.2V3.6H6.6V31.8C6.6 32.1314 6.86865 32.4 7.2 32.4H28.2V34.8ZM31.8 31.2H7.8V1.2H23.4V9C23.4 9.33135 23.6686 9.6 24 9.6H31.8V31.2Z" fill="#131353" />
        <path d="M14.4 5.40002H10.8C10.4686 5.40002 10.2 5.66867 10.2 6.00002V9.60002C10.2 9.93137 10.4686 10.2 10.8 10.2H14.4C14.7313 10.2 15 9.93137 15 9.60002V6.00002C15 5.66867 14.7313 5.40002 14.4 5.40002ZM13.8 9.00002H11.4V6.60002H13.8V9.00002Z" fill="#0095E8" />
    </svg>
);
