import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '../../components/button';
import Paragraph from '../../components/paragraph';

import GetcarLogo from '../../assets/icons/logo-getcar.png';
import InstagramLogo from '../../assets/icons/instagram.svg';

const Page = () => {
    return (
        <Container>
            <Row className="my-5 text-center">
                <Col xs={{ offset: 2, span: 8 }} md={{ offset: 4, span: 4 }}>
                    <img src={GetcarLogo} alt="" />
                </Col>
            </Row>
            <Row className="text-center">
                <Col xs={{ offset: 1, span: 10 }} md={{ offset: 4, span: 4 }}>
                    <Paragraph variant="primary">
                        Disfruta sólo de manejar, del resto nos preocupamos nosotros 😉
                    </Paragraph>
                    <Button block className="mt-5" variant="primary" href="tel:6004111000">
                        Asistencia en Ruta
                    </Button>
                    <Button block className="mt-4" variant="primary" href="tel:6004111000">
                        SOS &#8211; Accidente
                    </Button>
                    <Button block className="mt-4" variant="primary" href="tel:6003680300">
                        SOS &#8211; Robo Vehículo
                    </Button>
                    <Button block className="mt-4" variant="primary" href="https://api.whatsapp.com/send/?phone=56954565621&text=Hola%20buenas!%20Necesito%20ayuda">
                        Problemas con el Servicio
                    </Button>
                    <Button block className="mt-4" variant="secondary" href="https://api.whatsapp.com/send/?phone=56954565621&text=Hola%20buenas!%20Quiero%20hacerles%20una%20consulta">
                        ¡Conversemos!
                    </Button>
                </Col>
            </Row>
            <Row className="text-center my-5">
                <Col>
                    <a href="https://www.instagram.com/connectcar.cl" target="_blank" rel="noreferrer">
                        <img src={InstagramLogo} alt="" />
                    </a>
                </Col>
            </Row>
        </Container>
    );
};

export default Page;
