import React, { useEffect, useState } from 'react';
import _ from 'lodash';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Loader from '../../components/loader';
import ProgressStepper from '../../components/progress_stepper';
import GetcarLogo from '../../assets/icons/logo-getcar.png';
import ProgressBar from '../../components/progress_bar';
import WhatsappButton from '../../components/whstapp_button';

import useSessionUser from '../../hooks/use_session_user';
import useSubscription from '../../hooks/use_subscription';
import apiService from '../../services/api_service';

import MainIcon from '../../assets/icons/connect-car-icon.svg';
import SUBSCRIPTION_STATUS from '../../constants/subscription_status';

import useWindowActive from '../../hooks/use_window_active';

import {
    useParams,
    useSearchParams,
    Navigate,
} from 'react-router-dom';

import ContractPage from './step_contract';
import PaymentPage from './step_payment';
import SchedulePage from './step_schedule';
import Paragraph from '../../components/paragraph';

const STEPS = {
    CONTRACT_PENDING: 0,
    WARRANTY_PENDING: 1,
    PAYMENT_PENDING: 1,
    SCHEDULING_PENDING: 2,
};

const ONBOARDING_STEPS = [
    'Firma',
    'Pago',
    'Agendamiento',
];

const MAX_WAIT_SECONDS = 80;

const Page = () => {
    const windowActive = useWindowActive();
    const { subscriptionId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const flowStatus = searchParams.get('status');
    const prevStatus = searchParams.get('prevStatus');
    const [loadAmount, setLoadAmount] = useState(0);

    const user = useSessionUser();
    const [subscription, setSubscription] = useSubscription(subscriptionId);

    const poolSubscription = async (id, status) => {
        const response = await apiService.findOne('subscriptions', id, { populate: true });

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        if (_.isNull(response.data) || response.data.status === status) {
            setTimeout(() => poolSubscription(id, status), 3000);
        } else {
            setSearchParams({});
            setSubscription(response.data);
        }
    };

    const updateSubscription = async (id, newStatus) => {
        const response = await apiService.update('subscriptions', id, { status: newStatus });

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        if (_.isNull(response.data)) return;

        setSubscription(response.data);
    };

    useEffect(() => {
        if (
            loadAmount === 100
            || !windowActive
            || flowStatus !== 'updating'
        ) return;

        const currentSecond = loadAmount * MAX_WAIT_SECONDS / 100;
        let nextLoadAmount = 100 * (currentSecond + 1) / MAX_WAIT_SECONDS;

        if (nextLoadAmount > 100) {
            nextLoadAmount = 100;
        }

        setTimeout(() => setLoadAmount(nextLoadAmount), 1000);
    }, [flowStatus, loadAmount, windowActive]);

    useEffect(() => {
        if (flowStatus !== 'updating' || _.isEmpty(subscription)) return;

        const { id } = subscription;

        poolSubscription(id, prevStatus);
    }, [flowStatus, subscription]);

    if (_.isUndefined(subscription) || _.isUndefined(user)) {
        return <Loader icon={MainIcon} fullscreen />;
    }

    if (_.isEmpty(subscription) || _.isEmpty(user)) {
        return <Navigate to="/" replace/>;
    }

    const subscriptionStatus = subscription.status;

    const handleStepChange = () => {
        const { id, status } = subscription;

        if (status === SUBSCRIPTION_STATUS.SCHEDULING_PENDING) {
            updateSubscription(id, SUBSCRIPTION_STATUS.DELIVERY_PENDING);
        } else {
            setSearchParams({ status: 'updating', prevStatus: status });
            poolSubscription(id, status);
        }
    };

    const renderBody = () => {
        switch (subscriptionStatus) {
        case SUBSCRIPTION_STATUS.CONTRACT_PENDING:
            return <ContractPage user={user} subscription={subscription} onNextStep={handleStepChange} />;

        case SUBSCRIPTION_STATUS.WARRANTY_PENDING:
        case SUBSCRIPTION_STATUS.PAYMENT_PENDING:
            return <PaymentPage user={user} subscription={subscription} onNextStep={handleStepChange} />;

        case SUBSCRIPTION_STATUS.SCHEDULING_PENDING:
            return <SchedulePage user={user} subscription={subscription} onNextStep={handleStepChange} />;

        default:
            return <Navigate to="/" replace/>;
        }
    };

    if (flowStatus === 'updating') {
        let body;
        let loader;
        let button;

        if (subscriptionStatus === SUBSCRIPTION_STATUS.CONTRACT_PENDING) {
            body = (<>
                <b>Estamos procesando tu firma de contrato.</b>
                <br/>
                <small>Este proceso puede tardar algunos minutos.</small>
            </>);
            loader = (
                <ProgressBar
                    className="mt-5"
                    animated
                    variant="success"
                    now={loadAmount}
                    label={`${parseInt(loadAmount)}%`}
                />
            );
            if (loadAmount === 100) {
                button = (
                    <div className="my-5">
                        <Paragraph className="mb-3">
                            ¿Tienes un problema con la firma?
                        </Paragraph>
                        <WhatsappButton number={56954565621} text="Hola! Tengo un problema con la firma de contrato" variant="inline">
                            Escríbenos
                        </WhatsappButton>
                    </div>
                );
            }
        } else if (subscriptionStatus === SUBSCRIPTION_STATUS.WARRANTY_PENDING) {
            body = 'Estamos confirmando el pago de la garantía...';
            if (loadAmount === 100) {
                button = (
                    <div className="my-5">
                        <Paragraph className="mb-3">
                            ¿Tienes un problema con la garantía?
                        </Paragraph>
                        <WhatsappButton number={56954565621} text="Hola! Tengo un problema con el pago de la garantía" variant="inline">
                            Escríbenos
                        </WhatsappButton>
                    </div>
                );
            }
        } else if (subscriptionStatus === SUBSCRIPTION_STATUS.PAYMENT_PENDING) {
            body = 'Estamos confirmando el medio de pago...';
            if (loadAmount === 100) {
                button = (
                    <div className="my-5">
                        <Paragraph className="mb-3">
                            ¿Tienes un problema con el medio de pago?
                        </Paragraph>
                        <WhatsappButton number={56954565621} text="Hola! Tengo un problema con el medio de pago" variant="inline">
                            Escríbenos
                        </WhatsappButton>
                    </div>
                );
            }
        } else {
            return <Navigate to="/" replace/>;
        }

        return (
            <>
                <Loader icon={MainIcon} fullscreen>
                    <Row>
                        <Col xs={{ span: 8, offset: 2 }} md={{ span: 4, offset: 4 }}>
                            <p className="mt-2">{ body }</p>
                            { loader }
                            { button }
                        </Col>
                    </Row>
                </Loader>
            </>
        );
    }

    return (
        <Container>
            <Row>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    <Row className="mt-5 text-center">
                        <Col xs={{ offset: 2, span: 8 }} md={{ offset: 3, span: 6 }}>
                            <img src={GetcarLogo} alt="" />
                        </Col>
                    </Row>
                    <Row className="my-5 text-center">
                        <Col>
                            <Title textAlign="center" size="md">
                                Bienvenido al proceso de firma de contrato, pago y agendamiento de entrega
                            </Title>
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <ProgressStepper steps={ONBOARDING_STEPS} currentStep={STEPS[subscriptionStatus]} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            { renderBody() }
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default Page;
