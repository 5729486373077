import PropTypes from 'prop-types';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Title from '../../components/title';
import Paragraph from '../../components/paragraph';
import Button from '../../components/button';

import CheckCircle from '../../assets/icons/check-circle.svg';


const ValidationResult = (props) => {
    const { onFinishUrl } = props;

    return (
        <>
            <Row>
                <Col className="text-center">
                    <img src={CheckCircle} alt="" />
                </Col>
            </Row>
            <Title textAlign="center" variant="dark" className="my-4">
                ¡Perfecto!
            </Title>
            <Paragraph size="sm" textAlign="center" className="mb-5" variant="secondaryNewTheme">
                Todos tus datos fueron recibidos correctamente.
            </Paragraph>
            <Paragraph variant="secondaryNewTheme" textAlign="center" className="mb-5">
                Dentro de las próximas 24 horas nos pondremos en contacto contigo.
            </Paragraph>

            <Button block href={onFinishUrl} className="mb-5">
                Cerrar
            </Button>
        </>
    );
};


ValidationResult.propTypes = {
    onFinishUrl: PropTypes.string.isRequired,
};

export default ValidationResult;
