export default (props) => (
    <svg width="31" height="32" viewBox="0 0 31 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg"  {...props}>
        <path d="M29.205 19.3036C27.396 17.4947 24.7282 17.0556 22.5018 17.9856V7.25971C22.5018 6.8159 22.329 6.39866 22.0152 6.08489L16.4175 0.486529C16.2865 0.355582 16.134 0.248604 15.9686 0.168386C15.9683 0.168204 15.9679 0.168022 15.9675 0.16784C15.96 0.164138 15.9524 0.160376 15.9447 0.156857C15.9302 0.149757 15.9155 0.143143 15.9004 0.137257C15.6936 0.0481189 15.4692 0 15.2421 0H1.75607C0.787803 0 0 0.787743 0 1.75607V29.3447C0 30.313 0.787743 31.1007 1.75601 31.1007H20.7457C21.6729 31.1007 22.443 30.3758 22.4988 29.4504C22.4996 29.4382 22.5 29.426 22.5 29.4138V29.288C23.2565 29.6044 24.0638 29.7629 24.8714 29.7629C26.4408 29.7629 28.0102 29.1655 29.205 27.9708C30.3625 26.8132 31 25.2742 31 23.6372C31 22.0002 30.3625 20.4612 29.205 19.3036ZM20.4301 6.21596H16.4203C16.3462 6.21596 16.286 6.1557 16.286 6.08167V2.07142L20.4301 6.21596ZM21.2864 29.3922C21.262 29.6709 21.0272 29.8871 20.7458 29.8871H1.75607C1.45692 29.8871 1.21365 29.6438 1.21365 29.3447V1.75607C1.21365 1.45692 1.45698 1.21359 1.75607 1.21359H15.0725V6.08167C15.0725 6.82488 15.6771 7.42955 16.4203 7.42955H21.2883V18.665C21.0254 18.8546 20.7742 19.0672 20.5378 19.3036C19.3803 20.4612 18.7428 22.0002 18.7428 23.6373C18.7428 25.2743 19.3803 26.8133 20.5378 27.9709C20.7737 28.2067 21.0243 28.4189 21.2865 28.6082V29.3922H21.2864ZM28.3468 27.1127C26.4304 29.0291 23.3123 29.0291 21.3959 27.1127C20.4675 26.1843 19.9563 24.9501 19.9563 23.6373C19.9563 22.3244 20.4675 21.0901 21.3959 20.1618C22.3541 19.2036 23.6127 18.7245 24.8713 18.7245C26.1299 18.7245 27.3885 19.2036 28.3467 20.1618C29.2751 21.0902 29.7863 22.3244 29.7863 23.6373C29.7863 24.9501 29.2751 26.1843 28.3468 27.1127Z" fill="currentColor" />
        <path d="M4.27599 9.67111H12.7355C13.0706 9.67111 13.3423 9.39945 13.3423 9.06432C13.3423 8.72918 13.0706 8.45752 12.7355 8.45752H4.27599C3.94091 8.45752 3.66919 8.72918 3.66919 9.06432C3.66919 9.39945 3.94085 9.67111 4.27599 9.67111Z" fill="currentColor" />
        <path d="M14.8454 19.3749H4.27599C3.94091 19.3749 3.66919 19.6465 3.66919 19.9817C3.66919 20.3168 3.94091 20.5885 4.27599 20.5885H14.8455C15.1805 20.5885 15.4523 20.3168 15.4523 19.9817C15.4523 19.6465 15.1805 19.3749 14.8454 19.3749Z" fill="currentColor" />
        <path d="M14.8454 23.014H4.27599C3.94091 23.014 3.66919 23.2857 3.66919 23.6208C3.66919 23.956 3.94091 24.2276 4.27599 24.2276H14.8455C15.1805 24.2276 15.4523 23.956 15.4523 23.6208C15.4523 23.2857 15.1805 23.014 14.8454 23.014Z" fill="currentColor" />
        <path d="M18.2574 15.7357H4.27599C3.94091 15.7357 3.66919 16.0074 3.66919 16.3425C3.66919 16.6776 3.94091 16.9493 4.27599 16.9493H18.2574C18.5924 16.9493 18.8642 16.6776 18.8642 16.3425C18.8642 16.0074 18.5924 15.7357 18.2574 15.7357Z" fill="currentColor" />
        <path d="M4.21519 13.3496H15.1626C15.4977 13.3496 15.7694 13.0779 15.7694 12.7428C15.7694 12.4076 15.4977 12.136 15.1626 12.136H4.21519C3.88012 12.136 3.6084 12.4076 3.6084 12.7428C3.6084 13.0779 3.88006 13.3496 4.21519 13.3496Z" fill="currentColor" />
        <path d="M18.1825 13.3498C18.5178 13.3498 18.7896 13.078 18.7896 12.7427C18.7896 12.4074 18.5178 12.1356 18.1825 12.1356C17.8472 12.1356 17.5754 12.4074 17.5754 12.7427C17.5754 13.078 17.8472 13.3498 18.1825 13.3498Z" fill="currentColor" />
        <path d="M27.0172 21.4916C26.7802 21.2546 26.3961 21.2546 26.159 21.4916L24.8714 22.7791L23.6041 21.5118C23.3671 21.2749 22.9829 21.2749 22.7459 21.5118C22.5089 21.7488 22.5089 22.133 22.7459 22.37L24.0132 23.6372L22.7256 24.9249C22.4886 25.1618 22.4886 25.5461 22.7256 25.783C22.8441 25.9015 22.9994 25.9607 23.1546 25.9607C23.3099 25.9607 23.4652 25.9015 23.5837 25.783L24.8713 24.4954L26.1386 25.7627C26.2571 25.8812 26.4124 25.9405 26.5677 25.9405C26.723 25.9405 26.8783 25.8812 26.9967 25.7627C27.2337 25.5258 27.2337 25.1416 26.9967 24.9046L25.7294 23.6373L27.0171 22.3498C27.2542 22.1127 27.2542 21.7285 27.0172 21.4916Z" fill="#0095E8" />
    </svg>
);
