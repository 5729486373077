import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './components/private_route';

import { useQueryAsObject } from './hooks/use_query';
import { setItems } from './helpers/analytics_tracker';

import AuthPage from './views/auth';
import OnboardingSpecsPage from './views/onboarding/onboarding_by_specs';
import OnboardingIdPage from './views/onboarding/onboarding_by_id';
import OnboardingDocumentsPage from './views/onboarding_documents';
import SubscriptionsPage from './views/subscriptions';
import SubscriptionDetailPage from './views/subscriptions/detail';
import UserProfilePage from './views/user_profile';
import SubscriptionOnboardingPage from './views/subscription_onboarding';
import PrivateHomePage from './views/private_home';
import Layout from './views/layout';
import VehicleAssistance from './views/vehicle_assistance';
import ProtocolsPage from './views/protocols';

const MainRouter = () => {
    const query = useQueryAsObject();

    useEffect(() => { setItems(query); }, [query]);

    return (
        <Routes>
            <Route path="/auth/:step" element={ <AuthPage postLoginPath="/" /> } />

            <Route path="/onboarding" element={ <OnboardingSpecsPage /> } />
            <Route path="/onboarding/:vehicleId" element={ <OnboardingIdPage /> } />

            <Route path="/documents" element={ <OnboardingDocumentsPage/> } />

            <Route path="/help" element={<VehicleAssistance />} />

            <Route element={<PrivateRoute loginPath="/auth/login" />}>
                <Route path="/" element={ <Layout view={<PrivateHomePage />} /> } />
                <Route path="/subscriptions" element={<Layout view={<SubscriptionsPage />} /> } />
                <Route path="/subscriptions/:subscriptionId/onboarding" element={<SubscriptionOnboardingPage />} />
                <Route path="/subscriptions/:subscriptionId" element={<Layout view={<SubscriptionDetailPage />} /> } />
                <Route path="/profile" element={<Layout view={<UserProfilePage />} />} />
                <Route path="/protocols" element={<Layout view={<ProtocolsPage />} />} />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default MainRouter;
