import _ from 'lodash';
import { useEffect, useState } from 'react';

import apiService from '../services/api_service';

const { REACT_APP_VEHICLE_PROVIDER } = process.env;

export default (vehicleId) => {
    const [vehicle, setVehicle] = useState(undefined);

    const fetch = async (vehicleId, cancelToken) => {
        if (_.isEmpty(vehicleId)) return;

        const response = await apiService.findOne('vehicles', vehicleId, {
            populate: true,
            vehicleProviderId : REACT_APP_VEHICLE_PROVIDER || '',
        }, cancelToken);

        if (_.isEmpty(response) || !Object.keys(response).includes('data')) return;

        setVehicle(response.data);
    };

    useEffect(() => {
        const cancelToken = apiService.start();

        fetch(vehicleId, cancelToken);

        return () => apiService.cancel(cancelToken);
    }, [vehicleId]);

    return [vehicle, setVehicle];
};
