import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';

import apiService from '../../services/api_service';
import Title from '../../components/title';
import Paragraph from '../../components/paragraph';

import StepSuccess from './step_success';

import './style.css';

const VARIANT = {
    FIRST_LOGIN: 'FIRST_LOGIN',
    LOGGEDOUT_REQUEST: 'LOGOUT_REQUEST',
    LOGGEDIN_REQUEST: 'LOGGEDIN_REQUEST',
};

const Step = (props) => {
    const {
        variant,
        email,
        onNext,
    } = props;

    const [formState, setFormState] = useState({ email });
    const [error, setError] = useState(undefined);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(false);

    useEffect(() => {
        const { email } = formState;

        const isDisabled = isLoading || _.isEmpty(email) || !/[^@]+@[^.]+\./.test(email);

        setDisabled(isDisabled);

    }, [isLoading, formState]);

    const getTitle = () => {
        switch (variant) {
        case VARIANT.FIRST_LOGIN:
            return 'Solicita una contraseña';

        case VARIANT.LOGGEDOUT_REQUEST:
            return 'Crear o actualizar contraseña';

        case VARIANT.LOGGEDIN_REQUEST:
            return 'Cambia tu contraseña';
        }
    };

    const getSubtitle = () => {
        switch (variant) {
        case VARIANT.LOGGEDOUT_REQUEST:
            return 'Ingresa tu email y te enviaremos un link donde podrás actualizar o crear una nueva contraseña.';

        case VARIANT.FIRST_LOGIN:
            return 'Te enviaremos un link donde podrás crear una nueva contraseña.';

        case VARIANT.LOGGEDIN_REQUEST:
            return 'Te llegará un link a tu email, donde podrás crear tu nueva contraseña.';
        }
    };

    const getTextAlignment = () => {
        switch (variant) {
        case VARIANT.LOGGEDOUT_REQUEST:
        case VARIANT.FIRST_LOGIN:
            return 'center';

        case VARIANT.LOGGEDIN_REQUEST:
            return 'left';
        }
    };

    const handleFieldChange = (event) => {
        const { name, value } = event.target;

        setFormState({
            ...formState,
            [name]: value,
        });
    };

    const requestCredentials = async (payload) => {
        const { email } = payload;

        const redirectUrl = `${window.location.protocol}//${window.location.host}/auth/reset`;

        try {
            await apiService.post('userCredentialReset', {
                step: 'REQUEST',
                email,
                redirectUrl,
            });

            return null;
        } catch (error) {
            if (error.message.includes('Invalid email')) {
                return 'Tu email no es correcto';
            }

            return 'Ocurrió un error inesperado.';
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);

        const error = await requestCredentials(formState);
        if (error !== null) {
            setError(error);
        } else {
            setShowSuccess(true);
        }

        setIsLoading(false);
    };

    const renderSuccess = () => {
        let subtitle;

        if (formState.email) {
            const hiddenMail = formState.email.replace(/^(.{1})([^@]+)(@.+$)/, '$1***$3');
            subtitle = `Hemos enviado un email a ${hiddenMail} con un link para que recuperes el acceso a tu cuenta.`;
        } else {
            subtitle = 'Hemos enviado un email con un link para que recuperes el acceso a tu cuenta.';
        }
        return (
            <StepSuccess
                title={getTitle()}
                subtitle={subtitle}
                buttonText="Aceptar"
                onClick={onNext}
            />
        );
    };

    if (showSuccess) {
        return renderSuccess();
    }

    return (
        <>
            <Title textAlign={getTextAlignment()} size="sm">
                { getTitle() }
            </Title>
            <br/>
            <Paragraph textAlign={getTextAlignment()}>
                { getSubtitle() }
            </Paragraph>
            <form className="auth-form" onSubmit={handleSubmit}>
                <div className="auth-form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="hola@connectcar.cl"
                        defaultValue={formState.email}
                        disabled={!_.isEmpty(email)}
                        onChange={handleFieldChange}
                    />
                </div>
                <br/>
                <Alert variant="primary">
                    <Paragraph size="xxs" textAlign="center" variant="link">
                        <b>Importante:</b> El email expirará dentro de una hora una vez recibido.
                    </Paragraph>
                </Alert>
                <br/>
                <button type="submit" disabled={isDisabled}>
                    { isLoading ? 'Enviando a tu email...' : 'Enviar link' }
                </button>
                {
                    error && <Paragraph className="mt-3" variant="error" fontSize="xs">{ error }</Paragraph>
                }
            </form>
        </>
    );
};

Step.VARIANT = VARIANT;

Step.propTypes = {
    variant: PropTypes.oneOf(Object.values(VARIANT)),
    email: PropTypes.string,
    onNext: PropTypes.func.isRequired,
};

Step.defaultProps = {
    variant: VARIANT.LOGGEDOUT_REQUEST,
};

export default Step;
